@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace
            + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $iefix: null, $exts: eot woff2 woff ttf svg) {
    $eot: null;
    $src: null;

    $extmods: (
        eot: if($iefix, "?#iefix", "?"),
        svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
        otf: "opentype",
        eot: if($iefix, "embedded-opentype", "eot"),
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);

        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);

        @if $ext == eot {
            $eot: true;
        }

        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @if $iefix and $eot {
        @font-face {
            font-family: quote($name);
            src: url(quote($path + ".eot"));
            src: $src;
            font-style: $style;
            font-weight: $weight;
        }
    }

    @else {
        @font-face {
            font-family: quote($name);
            src: $src;
            font-style: $style;
            font-weight: $weight;
        }
    }
}

