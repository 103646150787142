@import 'mixins/fonts.scss';

@include font-face(
  'BasisGrotesquePro_400',
  '../fonts/BasisGrotesquePro/basis-grotesque-regular-pro',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'BasisGrotesquePro_500',
  '../fonts/BasisGrotesquePro/basis-grotesque-medium-pro',
  normal,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'BasisGrotesquePro_700',
  '../fonts/BasisGrotesquePro/basis-grotesque-bold-pro',
  bold,
  normal,
  null,
  woff2 woff
);
@include font-face(
  'BasisGrotesquePro_900',
  '../fonts/BasisGrotesquePro/basis-grotesque-black-pro',
  bold,
  normal,
  null,
  woff2 woff
);

p {
  margin: 0;
}

.container-corona {
  background-color: #ffd450;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.corona {
  width: 45px;
  height: 45px;
  margin-right: 20px;
}

.coronaArrow {
  width: 18px;
  height: 10px;
  object-fit: contain;
  margin-left: 5px;
}

.coronaText {
  font-size: 20px;
  color: #000000;
  font-family: 'BasisGrotesquePro_400';
  margin-right: 30px;
}

.coronaLink {
  font-size: 20px;
  color: #000000;
  font-family: 'BasisGrotesquePro_400';
  &_span {
    opacity: 0.4;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .container-corona {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .corona {
    width: 40px;
    height: 40px;
    margin: 5px;
  }

  .coronaArrow {
    width: 14px;
    height: 8px;
    margin-left: 0;
  }

  .coronaText {
    font-size: 13px;
    margin-right: 10px;
  }

  .coronaLink {
    font-size: 14px;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container-corona {
    display: -webkit-box;
    justify-content: center;
    align-items: center;
  }

  .corona {
    width: 40px;
    height: 40px;
    margin: 10px;
  }

  .coronaArrow {
    width: 18px;
    height: 10px;
  }

  .coronaText {
    font-size: 16px;
    margin-right: 10px;
  }

  .coronaLink {
    font-size: 16px;
    min-width: 120px;
  }
}
